"use strict";

/* SLIDER PUBLICATION START */
var publicationSlider = new Swiper('.publicationSlider', {


    slidesPerView: 'auto',
    centeredSlides: true,
    onSlideChangeEnd: function() {

        getCurrentDate();
    }
});

getCurrentDate();

function getCurrentDate() {
    "use strict";
    $('.currentDate .day').html($('.swiper-slide-active').attr('data-day'));
    $('.currentDate .monthYear').html($('.swiper-slide-active').attr('data-month'));
    $('.publicationSlider ').css({ 'height' : 'auto' });
    var activeslideheight = $('.swiper-slide-active').height();
    $('.publicationSlider ').css({ 'height' : activeslideheight+'px' })
}

$('.prevEvent').on('click', function() {
    publicationSlider.slidePrev();
});

$('.nextEvent').on('click', function() {
    publicationSlider.slideNext();
});
/*SLIDER PUBLICATION END*/

/*var slider_avions = new Swiper('.slider_avions', {

    nextButton: '.swiper-button-next.avions',
    prevButton: '.swiper-button-prev.avions',
    spaceBetween: 0,
    effect: 'fade',
    runCallbacksOnInit: true
});*/

$('.currentSlide').html('01');

var slider_gallery = new Swiper('.slider_gallery', {

    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 0,
    effect: 'fade',
    runCallbacksOnInit: true,
    onSlideChangeEnd: function() {

        $('.currentSlide').html('0' + (slider_gallery.activeIndex + 1));
    }
});

$("a.actif").prev().addClass("prev").css("display", "inline-block");
$("a.actif").next().addClass("next").css("display", "inline-block");

var total_slides = $('.slider_gallery .swiper-slide').length;
$('.totalSlides').html('0' + total_slides);

if ($("#homeReservationDate").length > 0) {

    document.querySelector("#homeReservationDate").addEventListener("click", function(e){
        var popup = document.querySelector("#ui-datepicker-div")
    
        const config = { attributes: true, childList: true, subtree: true };
        // le calendrier se reset quand on selectionne des dates, se qui supprime les events
        // à chaque reset, si un dimanche est selectionné et si la classe "changeOption" n'est pas sur la date, ce code est exécuté
        const callback = (mutationList, observer) => {

            for (const mutation of mutationList) {
                var allSunday = document.querySelectorAll(".ui-datepicker-calendar td:last-child")

                for (var elem of allSunday){
                    if (elem.classList.contains("ui-datepicker-current-day") && !elem.classList.contains("changeOption")){
                        elem.classList.add("changeOption")

                        var selectobject = document.querySelector(".ui_tpicker_hour_slider > select:nth-child(1)");
                        var selectobjectMinute = document.querySelector("dd.ui_tpicker_minute > div > select")
                        var homeReservationDate = document.querySelector("#homeReservationDate")
    
                        // les heurs avant 14h sont desactivé
                        for (var i = 0; i < selectobject.length; i++) {
                            if (selectobject.options[i].value < 14)
                                selectobject.options[i].setAttribute('disabled', true);
                        }

                        // les heurs avant 14h sont desactivées à chaque changement d'heur
                        selectobject.addEventListener("click", function(){
                            selectobject.addEventListener("change",function(){
                                document.querySelector(".changeOption").classList.remove("changeOption")

                                for (var i = 0; i < selectobject.length; i++) {
                                    if (selectobject.options[i].value < 14)
                                    selectobject.options[i].setAttribute('disabled', true);
                                }
                            });
                        });
    
                        // la nouvelle heur est enregister et envoyer dans le input avec l'id homeReservationDate
                        var currentDate = document.querySelector("#homeReservationDate").value

                        if (parseInt(selectobject.value) < 14){
                            selectobject.value = '14';
                            selectobjectMinute.value = '0';
                            currentDate = `${homeReservationDate.value.split(" ")[0]} 14:00`;
                            homeReservationDate.setAttribute('value', currentDate);
                        }

                        var btnValidate = document.querySelector("#ui-datepicker-div button.ui-datepicker-close")
                        
                        btnValidate.addEventListener("click", function(){
                            var minute = selectobjectMinute.value

                            currentDate = currentDate.split(":")[0] + (minute === '0' ? ':00' : ':30');
                            homeReservationDate.value = currentDate;
                        })

                        break
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(popup, config);
    
        checkDay()
    })
    
    // quand on click sur go pour afficher la popup de reservation la dates et l'heur dans input #homeReservationDate est récupéré
    document.querySelector("#reservationHomeForm > div.formItem.submit > button").addEventListener("click", function(e){
        setTimeout(() => {
            var reservation_date = document.querySelector("#reservation_date");
            var reservation_hours = document.querySelector("#reservation_hours");
            var homeReservationDate = document.querySelector("#homeReservationDate");
            reservation_date.value = homeReservationDate.value.split(" ")[0];
            reservation_hours.value = homeReservationDate.value.split(" ")[1];
        }, 500);
    });
}


/** RESERVATION EXPERIENCE **/
var reservationDate = true;
function checkDay() {
    var selectobject = document.querySelector(".ui_tpicker_hour_slider > select:nth-child(1)");
    var hour_start_reservation = 9;
    var disabledDay = false;
    var dateParts = document.getElementById("homeReservationDate").value.split("/");
    var getMonth = dateParts[2].substr(0, 5);
    var dateObject = new Date(+getMonth, dateParts[1] - 1, +dateParts[0]);

    if (dateObject.toLocaleDateString('en-US', {weekday: 'long'}) == 'Sunday') {
        hour_start_reservation = 14;
        disabledDay = false;
        // Condition spécial pour le Lundi pas d'horaires
    } else if (dateObject.toLocaleDateString('en-US', {weekday: 'long'}) == 'Monday') {
        disabledDay = true;
        // le reste de la semaine (9h-11h et du 14h-18h)
    } else {
        hour_start_reservation = 9;
    }
    if (disabledDay == true) {
        selectobject.disabled = false;
    }
}


function addReservation(product_id, data_value, exeperienceType = null) {
    if (exeperienceType != 'reservation') {
        reservationDate = false;
    }

    var targetReservation = $('#reservationBox_' + product_id);
    var date = '';
    var hour = '';

    if (!product_id) {
        product_id = changeReservation();
        date = $('#homeReservationDate').val().split(" ")[0];
        hour = $('#homeReservationDate').val().split(" ")[1];
        $('.homeReservationWrapper').css('zIndex', '5020');
        $('#shade').fadeIn();
        $('#reservationLightbox').addClass('fromHome');
        $('.homeReservationWrapper').append($('#reservationLightbox'));
        $('#reservationLightbox').slideDown();
    }

    $.ajax({
        url: path_relative_root+'ajax_show_reservation.php',
        type: 'POST',
        data: {
            produit_id: product_id,
            date: date + ' ' + hour
        },
        success: function(res) {
            $('#reservationLightbox').html(res);

            targetReservation.append($('#reservationLightbox'));

            targetReservation.slideDown(200, function() {
                var tagetTopPos = targetReservation.offset().top;
                $('html, body').animate({ scrollTop: tagetTopPos - 220 }, 0);
            });
            date = $('#homeReservationDate').val().split(" ")[0];
            hour = $('#homeReservationDate').val().split(" ")[1];
            $('#reservation_date').val(date);
            $('#reservation_hours').val(hour);
        },
        complete : function () {
            $('#reservation_type_2').val(2);
            $("#passengerInfos_2 .formItem.select p").html(translate("its_a_gift"));
            $("#reservationBox_" + product_id + " #reservationLightbox").css("display"," block")

            if (data_value === 2) {
                $("#passengerInfos_1 .formItem.select p").html(translate("its_a_gift"));
                $("#reservationForm .dateTime .formItem.date").css("display","none");
                $("#reservationForm .dateTime .formItem.select:last").css("display","none");
                $('#reservation_date').val('');
            } else {
                $("#passengerInfos_1 .formItem.select p").html(translate("its_me"));
                $("#reservationForm .dateTime .formItem.date").css("display","block");
                $("#reservationForm .dateTime .formItem.select:last").css("display","block");
            }
        }
    });
}

function bodyScrollUnlock(){
    "use strict";
    $('body').css({
        'overflow' : 'inherit',
        'padding-right' : '0'
    });
    $('body:not(.zoom_img_on) #site_head_wrap').css({
        'padding-right' : '0'
    });
}

function changeReservation() {
    var tabOption = document.querySelectorAll('#reservation option');
    for (option of tabOption) {
        if (option.selected) {
            return option.value;
        }
    }
    return 1;
}

function closeReservation(product_id) {
    $("#reservationBox_" + product_id).slideUp();
    $('#shade').fadeOut();
    bodyScrollUnlock();

    if ($(".fromHome").length) {

        $(".fromHome").slideUp(600, function () {

            $('.homeReservationWrapper').css('zIndex', '3');
            $('#reservationLightbox').removeClass('fromHome');
        });
    }
}

/* Toggle between 1 view & 2 views*/
$('div.item').addClass('full');
$('#oneProduct').addClass('actif');
$('#twoProducts').removeClass('actif');

$('div.toggleNumObjects').click(function() {

    if ($('#twoProducts').hasClass('actif')) {

        $('div.item').addClass('full');
        $('#twoProducts').removeClass('actif');
        $('#oneProduct').addClass('actif');
        createCookie('nb_produits', 1, 1);
    } else {
        $('div.item').removeClass('full');
        $('#twoProducts').addClass('actif');
        $('#oneProduct').removeClass('actif');
        eraseCookie('nb_produits');
    }
});

/* SLIDER HOME PAGE */
    var swiperHome = new Swiper('.mainSliderHomeImg', {
        nextButton          : '.swiper-button-next',
        prevButton          : '.swiper-button-prev',
        preloadImages       : false,
        lazyLoading         : true,
        autoHeight          : true,
        loop                : true,
    });

    var swiperHomeTxt = new Swiper('.mainSliderHomeText', {
        effect              : 'fade',
        noSwiping           : true,
        loop                : true
    });

    var swiperHomeTxtBis = new Swiper('.mainSliderHomeTextBis', {
        effect              : 'cube',
        loop                : true,
        noSwiping           : true,
        cube: {
            shadow          : false,
            slideShadows    : false
        }
    });

    swiperHome.params.control           = swiperHomeTxt;
    swiperHomeTxt.params.control        = swiperHomeTxtBis;
    swiperHomeTxtBis.params.control     = swiperHomeTxt;

    /* Home reservation date picker */
    $.datepicker.regional['fr'] = {
        closeText: 'Fermer',
        prevText: '',
        nextText: '',
        currentText: 'Current',
        monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin',
        'Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
        monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jun',
        'Jul','Aoû','Sep','Oct','Nov','Déc'],
        dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
        dayNamesShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
        dayNamesMin: ['dim.','lun.','mar.','mer.','jeu.','ven','sam.'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['fr']);

    $.timepicker.regional['fr'] = {
        timeOnlyTitle: 'Choisir une heure',
        timeText: 'Heure',
        hourText: 'Horaire',
        minuteText: 'h',
        currentText: 'Maintenant',
        closeText: 'Valider',
        timeFormat: 'HH:mm',
        timeSuffix: '',
        hourMin : 10,
        hourMax : 17,
        stepMinute : 30,
        maxTime : '17:00'
    };
    $.timepicker.setDefaults($.timepicker.regional['fr']);

    function disableCustomDate(date) {

        var year = date.getFullYear(),
            month = date.getMonth()+1, // month start at 0
            day = date.getDate(),
            weekDay = date.getDay();

        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;

        var current = year + '-' + month + '-' + day;

        if ($.inArray(current, arrayDisabledBookingDates) != -1) {
            return [false];
        }

        if (weekDay == 1) {
            return [false];
        }

        return [true];
    }

    $('.homeReservationDate').datetimepicker({
        numberOfMonths: 1,
        buttonImageOnly: true,
        defaultDate: 1,
        minDate: 1,
        oneLine: false,
        controlType: 'select',
        showTime: true,
        timeFormat: 'HH:mm',
        closeText: 'Valider',
        beforeShow: function() {
            setTimeout(function() {
                $('#ui-datepicker-div').prepend("<div class='closeReservation closeDatePicker'><span></span></div>");
                $('.closeDatePicker').on('click', function () {
                    $('.homeReservationDate').datetimepicker("hide");
                });
            }, 100 );
        },
        beforeShowDay: disableCustomDate
    });

function checkreservation(special_reservation) {
    var error = false;
    var date_now = new Date();

    if (special_reservation == '1' && special_reservation != 'undefined') {
        return false;
    }

    // Check Reservation Date
    if ($('#reservation_date').val() == '' && reservationDate) {
        $('#reservation_date').addClass('error');
        error = true;
    } else {
        var reservation_date = $('#reservation_date').val().split('/');

        if ((reservation_date[0] == '' || reservation_date[1] == '' || reservation_date[2] == '') && reservationDate) {
            $('#reservation_date').addClass('error');
            error = true;
        } else {
            var date_selected = new Date(reservation_date[2] + '-' + reservation_date[1] + '-' + reservation_date[0]);

            if (date_selected < date_now && reservationDate) {
                $('#reservation_date').val('');
                $('#reservation_date').addClass('error');
                error = true;
            } else {
                $('#reservation_date').removeClass('error');
            }
        }
    }

    // Check Reservation Passagers
    var nb_passenger = $('#reservation_nb_passagers').val();

    for (var i = 1; i <= nb_passenger; i++) {
        $('#reservation_nom_' + i).removeClass('error');
        $('#reservation_prenom_' + i).removeClass('error');
        $('#reservation_email_' + i).removeClass('error');

        if ($('#reservation_nom_' + i).val() == '') {
            $('#reservation_nom_' + i).addClass('error');
            error = true;
        }

        if ($('#reservation_prenom_' + i).val() == '') {
            $('#reservation_prenom_' + i).addClass('error');
            error = true;
        }

        if ($('#reservation_email_' + i).val() == '') {
            $('#reservation_email_' + i).addClass('error');
            error = true;
        } else {
            var mail_rule = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$","gi");
            if (mail_rule.exec($('#reservation_email_' + i).val()) == null) {
                $('#reservation_email_' + i).addClass('error');
                error = true;
            }
        }
    }
    return error;
}

function addToBasketClick(btn)
{
    try {
        var options = JSON.parse(btn.attr('data-options'));
    } catch (e) {
        return false;
    }

    if (typeof ga != 'undefined') {
        ga('send', 'event', options.category, 'Ajout Panier', options.product_ref);
    }

    var from = '';
    var loader;
    addToBasket(options.product_id, options.suff, options.basket_id, options.prod_title_object, options.idTaille, options.lastTaille, options.idCouleur, options.lastCouleur, options.toEval, options.page_name, options.path_web, options.enable_omniture, options.is_not_produit, from, loader, options.configurator_product, options.configurator_option, options.is_reservation);
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, configurator_product, configurator_option, is_reservation) {

    var id = '';

    prix_id = 'prixU';

    if (from == 'basket' || is_not_produit == true) {
        id += '_' + produit_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }
    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, configurator_product, configurator_option, is_reservation);

    if (configurator_option === true) {
        return true;
    }

    if (array_panier != false && is_reservation !== true) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajouté au panier
        } else {
            omnitureEvent = 'scAdd'; // il y a déjà un produit au panier
        }

        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void(s.t());
            s.pageName  = "'" + page_name + "'";
            s.products  = ";" + produit_id;
            s.events    = omnitureEvent;
            void(s.t());
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response_basket);

        $("#shad").hide();
        $("#lightbox_achat_express").removeClass('opened');

        newshowmodal(produit_id, is_not_produit);
    } else {
        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response_basket);

        return true;
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function bodyScrollLock() {
    "use strict";

    $('body:not(.body_fixed)').css({
        'overflow' : 'hidden'
    });
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur, configurator_product, configurator_option, is_reservation)
{
    if (i != '') {
        i = '_' + i;

        prixU = $('#prixU' + i).val();
    }

    var couleurs = $('#couleurProd' + i);
    var tailles = $('#tailleProd' + i);
    var res = '';
    var html_err = '';
    var flagError = false;

    if ($('#lightbox_achat_express').length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_size');
        flagError = true;
    } else if (couleurs && couleurs.val() == '') {
        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {
        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {
        var qte = 0;

        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none') {
            idCouleur = couleurs.val();
        }

        if (tailles && tailles.val() != 'none') {
            if (tailles.attr('class') !== undefined) {
                idTaille = tailles.attr('class');
            }else{
                idTaille = tailles.val();
            }
        }

        var mode = '';
        if (configurator_product == true) {
            mode = '&configurator_product=true';
        }

        // Add Reservation
        if (is_reservation === true) {
            var param_reservation = $('#reservationForm').serializeArray();
            var commande_reservation_id = false;

            if (!reservationDate) {
                param_reservation.forEach((data) => {
                    if (data.name == 'reservation_date') {
                        if (data.value == '') {
                            data.value = ('00/00/0000') ;
                        }
                    }
                });
            }

            $.ajax({
                url : path_relative_root + 'ajax_add_reservation.php',
                type : 'post',
                data : {
                    array_params : param_reservation
                },
                success : function (res) {
                    // RAZ au cas où
                    $('#valid_reservation').each(function() {
                        options = JSON.parse($(this).attr('data-options'));
                        $('#qteProd' + options.product_id).val(0);
                    });
                    // Recupération du panier virtuel id pour definir que les ecusson sont des enfant du produit principal
                    var panier_virtuel_id = res.panier_virtuel_id;

                    var produit_img = res.produit_img;
                    var produit_title = res.produit_title;
                    var produit_price = res.produit_price;
                    var array_produit_caract = res.array_produit_caract;
                    var reservation_date = res.reservation_date;
                    var reservation_heure = res.reservation_heure;
                    var reservation_video = res.reservation_video;
                    var reservation_nb_passagers = res.reservation_nb_passagers;
                    var duration_wish = res.tailleProd;
                    var array_passenger = res.array_passenger;
                    var special_reservation = res.special_reservation;
                    var reservation_amount = res.reservation_amount;
                    commande_reservation_id = res.commande_reservation_id;

                    var str_caract = '';
                    var str_passenger = '<label>' + translate('passengers') + ' : </label>';
                    var str_email = '<label>' + translate('lightbox_adwords_email') + ' : </label>';
                    var str_video = '';

                    $.each(array_produit_caract, function(i, val) {
                        if (val.caracteristique_lib == 'Type de vol') {
                            str_caract += "<span>" + translate('type') + " : " + val.caracteristique_val + "</span>";
                        }
                    });

                    $.each(array_passenger, function(i, val) {
                        if (i == 2) {
                            str_passenger += ", ";
                            str_email += ", "
                        }

                        str_passenger += "<span class='passagerGondole'>";
                        str_passenger += "<span>" + val.reservation_prenom + " " + val.reservation_nom + "</span>";
                        str_passenger += "</span>";
                        str_email += "<span class='emailGondole'>";
                        str_email += "<span>" + val.reservation_email + "</span>";
                        str_email += "</span>";
                    });

                    if (reservation_video == 0) {
                        str_video = translate('no_choose_option_video');
                    } else {
                        produit_price = '<span class="unit">' + reservation_amount + '</span><sup class="devise">&euro;</sup>';
                        var option_video = $('#product_video');
                        addToBasketClick(option_video);
                        str_video = translate('choose_option_video');
                    }

                    duration_wish_front = parseInt(tailleProd.value) - 5;
                    duration_wish = duration_wish_front + "/" + parseInt(tailleProd.value);
                    unite_duration = "min";

                    if (tailleProd.value == 60){
                        duration_wish = "1 ";
                        unite_duration = "heure";
                    }

                    duration_wish = duration_wish + unite_duration;
                    // On cache le loading et on affiche les bouton pour acceder au panier
                    $('.reservationFormWrapper').css('display', 'none');
                    $('.configDate').css('display', 'none');
                    $('#config_img').attr('src',produit_img); // Product Image
                    $('#config_title').html(produit_title); // Product Titre
                    $('#config_price').html(produit_price); // Product Prix

                    if (reservation_date != '00/00/0000' && special_reservation == 0) {
                        $('.configDate').css('display', 'unset');
                        $('#config_date').html(reservation_date); // Reservation Date
                        $('#config_hour').html(reservation_heure); // Reservation Heure
                    }

                    $('#config_qte').html(reservation_nb_passagers); // Reservation Nb passager
                    $('#duration_wish').html(duration_wish); // Product Prix
                    if (special_reservation == 0)  {
                        $('#config_video').html(str_video); // Product Prix
                        $('#bloc_config_caract').html(str_caract); // Product Caract
                        $('#bloc_config_passenger').html(str_passenger); // Passegner data
                        $('#configEmail').html(str_email); // Passenger email
                    }
                    $('#reservationConfirm').css('display', 'block');

                    $("#shade").css('opacity', '0.6').fadeIn().click(function(evt) {
                        close_lightbox('reservationConfirm');
                        closeReservation(res.prod_id);
                    });
                    bodyScrollLock();
                },
                async: false
            });
        }

        if (commande_reservation_id && is_reservation === true) {
            mode += '&reservation=' + commande_reservation_id;
        }

        var array_response = [];

        //On vérifie si c'est une vidéo
        if (idProduit == 12){
            //On fixe la taille à 1
            idTaille = 1;
        }
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId + mode);

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match('^[0-9]')) {
                if (designation == 1) {
                    designation += ' lot de';
                } else {
                    designation += ' lots de';
                }
            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                } else if (lastTaille != '') {
                    designation += ' - Taille ' + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {
                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                    designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                } else if (lastCouleur != '') {
                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == 'false') {
                $('#size_' + idTaille).addClass('disabled');
                alert(translate('product_no_stock_2'));
                return false;
            }

            if (is_reservation === true) {
                return true;
            }
        }
        return array_response;
    }
}

$(document).ready(function(){

    var $tabs = $("#sub-cats .swiper-wrapper");

    // Tabs click event
    $tabs.on('click', '.swiper-slide', function() {

        var tab_id = $(this).attr('data-tab');

		$tabs.find('.swiper-slide.current').removeClass('current');
		$('.tab-content.current').removeClass('current');

		$(this).addClass('current');
        $("#"+tab_id).addClass('current');

    });

    var swiper = new Swiper('#tmSwipers.swiper-container', {
        loop: false,
        slidesPerView: 4,
        slidesPerGroup: 2,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev'
    });

    /*atelier swiper*/
    var swiper_atelier = new Swiper('.slider_atelier', {
        slidesPerView: 1.15,
        spaceBetween: 5,
        loop: true,
        nextButton: '.atelier_wrapper .swiper-button-next',
        prevButton: '.atelier_wrapper .swiper-button-prev',
    });
});

var afficheLostPassword = function (evt) {

    var auth = $('#auth');
    var lostpwd = $('#lostpwd');
    var lost_pwd_btn = $('#lost-pwd-btn');

    if (typeof evt !== 'undefined') {
        evt.preventDefault();
    }

    if ($('#lostpwd').length > 0) {
        var func = $('#lostpwd').is(':visible') ? 'slideUp' : 'slideDown';

        if (func === 'slideUp') {
            lostpwd.css({
                'display': 'none',
                'opacity': '0'
            });
            auth.css('display', 'block').animate({
                opacity: 1
            }, 600);
            lost_pwd_btn.css('display', 'block').animate({
                opacity: 1
            }, 600);
        } else {
            lostpwd.css('opacity', '0');
            lostpwd.css('display', 'block').animate({
                opacity: 1
            }, 600);

            auth.css({
                'display': 'none',
                'opacity': '0'
            });
            lost_pwd_btn.css({
                'display': 'none',
                'opacity': '0'
            });

        }
    }
};

var old_obj = null;
var old_parent = null;
var old_id = null;
function activeFormProduitOffert( obj, id, event ) {
    var parent = $(obj);
    obj = $('.btn_radio',parent);
    var container = $(obj).parents('.content_product');
    var clickable;

    if (old_obj == null) {
        old_obj = obj;
        old_parent = $(obj);
        old_id = id;
    }

    if ($(event.target).closest('.third_bloc_produit_offert').length == 0 && nb_selection_offert_restant > 0){
        clickable = true;
    } else {
        clickable = false;
    }

    if (clickable == true){

        if (!parent.hasClass('disable')){

            if (obj.hasClass('actif')) {

                stop_prod_offert = false;

                $( '#selectionOffertePopup .ligne_produit_offert .third_bloc_produit_offert' ).each(function() {
                    $(this).parent().removeClass('inactif');
                });

                obj.removeClass('actif');
                parent.removeClass('seleted');

                $("#form_produit_offert_"+id).stop(true,true).css({display : 'block', opacity : 1}).animate({opacity : 0},function() {
                    $("#form_produit_offert_"+id).css({opacity : '',display : 'none'});
                    $("#liste_qte_"+id).html('');
                });

                if (typeof nbrGiftProd == 'number' ){

                    nbrGiftProd++;
                    $('#nb_prod_selection_offerte_lightbox').html(nbrGiftProd);
                }
                if (nbrGiftProd == 0) {
                    $('.ligne_produit_offert .btn_tunnel').removeClass('disable_valider');
                } else {
                    $('.ligne_produit_offert .btn_tunnel').addClass('disable_valider');
                }
            } else {
                if (!stop_prod_offert && nbrGiftProd != 0) {

                    showQteListeGamme('liste_qte_'+id, id, path_relative_root);

                    $("#form_produit_offert_"+id).stop(true,true).css({display : 'block', opacity : 0}).animate({opacity : 1},function(){

                        $("#form_produit_offert_"+id).css({opacity : ''});
                    });

                    obj.addClass('actif');
                    parent.addClass('seleted');
                    $('.btn_choose_product').appendTo("#form_produit_offert_"+id).show();
                    var targetForm = $("#form_produit_offert_"+id);
                    var formPos = targetForm.offset().top - ((targetForm.height()) * 1.8);
                    $('html, body').animate( { scrollTop: formPos }, 600 );

                    if (typeof nbrGiftProd == 'number') {

                        nbrGiftProd--;
                        $('#nb_prod_selection_offerte_lightbox').html(nbrGiftProd);
                    }
                    if (nbrGiftProd == 0) {
                        $('.ligne_produit_offert .btn_tunnel').removeClass('disable_valider');
                    } else {
                        $('.ligne_produit_offert .btn_tunnel').addClass('disable_valider');
                    }

                    old_obj = obj;
                    old_parent = parent;
                    old_id = id;
                } else if (!stop_prod_offert && nbrGiftProd == 0) {
                    //désélection
                    if (nbrGiftProd == 0) {
                        $('.ligne_produit_offert .btn_tunnel').removeClass('disable_valider');
                    } else {
                        $('.ligne_produit_offert .btn_tunnel').addClass('disable_valider');
                    }
                    stop_prod_offert = false;

                    old_obj.removeClass('actif');
                    old_parent.removeClass('seleted');

                    $("#form_produit_offert_"+old_id).stop(true,true).css({display : 'block', opacity : 1}).animate({opacity : 0},function(){

                        $("#form_produit_offert_"+old_id).css({opacity : '',display : 'none'});
                        $("#liste_qte_"+old_id).html('');
                    });

                    //séléction

                    setTimeout(function(){
                        $( '#selectionOffertePopup .ligne_produit_offert .third_bloc_produit_offert' ).each(function(){
                            $(this).parent().removeClass('inactif');
                        });

                        showQteListeGamme('liste_qte_'+id, id, path_relative_root);

                        $("#form_produit_offert_"+id).stop(true,true).css({display : 'block', opacity : 0}).animate({opacity : 1},function(){
                            $("#form_produit_offert_"+id).css({opacity : ''});
                        });

                        obj.addClass('actif');
                        parent.addClass('seleted');
                        $('.btn_choose_product').appendTo("#form_produit_offert_"+id).show();
                        var targetForm = $("#form_produit_offert_"+id);
                        var formPos = targetForm.offset().top - ((targetForm.height()) * 1.8);
                        $('html, body').animate( { scrollTop: formPos }, 500 );
                    }, 500);

                    old_obj = obj;
                    old_parent = parent;
                    old_id = id;

                }
            }

            $('.ligne_produit_offert').each(function() {
                if ($(this).hasClass('disable')){
                    $(this).removeClass('disable');
                    $(this).stop(true).css({display : 'block'}).animate({opacity : 1},300,function() {
                        $(this).css({opacity : ''});
                    })
                } else {
                    $(this).removeClass('disable');
                }
            });
        }
    }
}
// Toggles user account menu visibility
function toggleAccountMenu() {

	var $btn = $(this),
		menu = $(document.getElementById('account_menu')),
		marker = $('.marker', menu),
		body = $('body');
		offset = this.offsetLeft + (0.5 * this.offsetWidth),
        search = $('#top_search');

	offset += -6;

	marker.css('left', offset);

	body.toggleClass('noscroll');
	$btn.add(menu).toggleClass('active');
    search.toggleClass('hideSearch');
}

$(".bottomInfosWrapper button").each(function() {
    $(this).click(function() {
        $(".bottomInfosWrapper button").removeClass("borderR").addClass("redB");
        var data = $(this).data("value");
        $(this).siblings().removeClass("redB").addClass("borderR");
        $(this).removeClass("borderR").addClass("redB");
    })
});

function openMultiShad(id) {
    modBoxOpen.call(document.getElementById(id));
}
